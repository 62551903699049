import { createApiStore } from '../../api';

const apiStore = createApiStore([
  {
    action: '_getPressList',
    property: 'pressList',
    path: 'press/list',
    method: 'get',
  },
  {
    action: '_getPressView',
    property: 'pressInfo',
    path: ({no}) => `press/${no}`,
    method: 'get',
  }
]);

export default {
  namespaced: true,
  mixins: [apiStore],
  state: {
    pressList: [],
    pressInfo:{}
  },
  actions: {
    async getPressList({ state, dispatch }, params) {
      const response = await dispatch(`_getPressList`, { params });
      state.pressList = response.data
      return state.pressList;
    },
    async getPressView({ state, dispatch }, params) {
      const response = await dispatch(`_getPressView`, params);
      state.pressInfo = response.data
      return state.pressInfo;
    },

  },
  mutations: {},
};
