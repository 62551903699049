import Vue from 'vue'
import Vuex from 'vuex'
import { applyMixin } from './storeMixin'

import actions from './actions'
import getters from './getters'

Vue.use(Vuex)

const requireModules = require.context('./modules', false, /\.js$/)
const modules = requireModules.keys().reduce((modules, fileName) => {
    const name = fileName.match(/([^./]+)\.js$/)[1]
    modules[name] = requireModules(fileName).default
    return modules
}, {})

const mutations = {
    toggleSidebarDesktop (state) {
        const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
        state.sidebarShow = sidebarOpened ? false : 'responsive'
    },
    toggleSidebarMobile (state) {
        const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
        state.sidebarShow = sidebarClosed ? true : 'responsive'
    },
    set (state, [variable, value]) {
        state[variable] = value
    }
}

export default new Vuex.Store(applyMixin({
    state: {
        sidebarShow: 'responsive',
        sidebarMinimize: false,
        sideShow: true,
        navs: []
    },
    actions,
    getters,
    modules,
    mutations
}))