export default {
    namespaced: true,
    state: {
        loading: false,
        title:'',
        content:''
    },
    mutations: {
        done (state) {
            state.loading = false
        },
        init (state, loadingContent) {
            state.loading = true
            if(loadingContent.title) {
                state.title = loadingContent.title
            } else {
                state.title = ''
            }
            if(loadingContent.content) {
                state.content = loadingContent.content
            } else {
                state.content = ''
            }

        }
    }
}
