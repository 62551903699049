import api from "../api/api";
import store from '../store/index'
import getContractApi from "@/api/contract";


export function checkAccessToken (to, from, next) {
    if (localStorage.getItem('access_token')) {
        api.defaults.headers.Authorization = `Bearer ${localStorage.getItem('access_token')}`
    }
    next()
}

export function auth(to, from, next) {
    if (to.matched.some(record => record.meta.needAuth)) { // auth가 필요한 부분인데

        const market = getContractApi();
        market.getWineMarket().getUserAddress().then(data => {
            if (data === undefined) {
                alert('메타마스크 연결을 확인해 주세요.')

                return next({
                    path: `/`
                })
            }else{
                store.state.user.walletAddress = data;
                return next()
            }
        })
    } else {
        return next()
    }
}

export function isLoading(to, from , next) {
    if(to.meta.loading){
        alert('현재 준비중입니다')
        return next();
    }else{
        return next();
    }
}

export function dispatchAction (to, from, next) {
    if (to.meta.action) {
        store.dispatch(`beforeEnter${to.name}`, { to, from })
    }
    next()
}

export function metaTagCheck(to, from, next) {
    if(to.meta.title) {
        document.title = to.meta.title
    }
    next()
}