import { createApiStore } from '../../api';

const apiStore = createApiStore([
  {
    action: '_getFineWineList',
    property: 'fineWineList',
    path: 'fine_wine/list',
    method: 'get'
  }
]);

export default {
  namespaced: true,
  mixins: [apiStore],
  state: {
    fineWineList:[]
  },
  actions: {
    async getFineWineList({ state, dispatch }, params) {
      const response = await dispatch(`_getFineWineList`, { params });
      state.fineWineList = response.data
      return state.fineWineList;
    },

  },
  mutations: {},
};
