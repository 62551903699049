import { createApiStore } from '../../api'
import api from '../../api/api'

const apiStore = createApiStore([
    {
        action: '_adminLogin',
        property: 'oauth',
        path: 'user/login',
        method: 'post'
    },
    {
        action: '_getMe',
        property: 'me',
        path: 'user/me',
        method: 'get'
    },
    {
        action: '_refreshToken',
        property: 'oauth',
        path: 'oauth',
        method: 'put'
    },
    {
        action: '_selectUser',
        property: 'users',
        path: 'user',
        method: 'get'
    },
    {
        action: '_getUser',
        property: 'user',
        path: 'user',
        method: 'get',
        onSuccess (state, payload) {
            if (payload.data.data.length > 0) {
                state.user = payload.data.data[0]
            } else {
                state.user = null
            }
        }
    },
])

export default {
    namespaced: true,
    mixins: [apiStore],
    state: {
        me: {},
        oauth: {
        },
        refreshToken: {
        },
        users: {
            data: []
        },
        user: null,
        walletAddress: null
    },
    actions: {
        async adminLogin ({state, dispatch}, data) {
            await dispatch(`_adminLogin`, {data})
            localStorage.setItem('access_token', state.oauth.access_token)
            localStorage.setItem('refresh_token', state.oauth.refresh_token)
            api.defaults.headers.Authorization = `Bearer ${state.oauth.access_token}`
            if (state.oauth.access_token) {
                return state.oauth
            } else {
                return null
            }
        },
        async getMe ({state, dispatch}) {
            await dispatch('_getMe')
            return state.me
        },
        async refreshToken ({dispatch, state}) {
            await dispatch(`_refreshToken`, {
                data: {
                    grant_type: 'refresh_token',
                    refresh_token: localStorage.getItem('refresh_token')
                }
            })

            localStorage.setItem('access_token', state.oauth.access_token)
            localStorage.setItem('refresh_token', state.oauth.refresh_token)
            api.defaults.headers.Authorization = `Bearer ${state.oauth.access_token}`
            if (state.oauth.access_token) {
                return state.oauth
            } else {
                return null
            }
        },
        async selectUser({dispatch}, params) {
            params['sort[by]'] = 'created_at'
            params['more_field'] = '*'
            await dispatch('_selectUser', {params})
        },
        async getUser({dispatch}, params) {
            params['more_field'] = '*'
            await dispatch('_getUser', {params})
        }
    },
    mutations: {
    }
}