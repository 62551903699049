import { createApiStore } from '../../api';

const apiStore = createApiStore([
  {
    action: '_getWineMarketList',
    property: 'wineMarketList',
    path: 'nft/list/wine',
    method: 'get'
  }
]);

export default {
  namespaced: true,
  mixins: [apiStore],
  state: {
    wineMarketList:[]
  },
  actions: {
    async getWineMarketList({ state, dispatch }, params) {
      const response = await dispatch(`_getWineMarketList`, { params });
      state.fineWineList = response.data
      return state.fineWineList;
    },

  },
  mutations: {},
};
