export default {
    install(Vue) {
        const pt = Vue.prototype;
        pt.$numberFormat = lib.numberFormat
    }
}

export const lib = {
    numberFormat: (number) => {
        if(number) {
            return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        } else {
            return 0
        }
    }
}
