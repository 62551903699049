import { createApiStore } from '../../api';

const apiStore = createApiStore([
  {
    action: '_getWineFilterList',
    // property: 'wineFilterList',
    path: 'filter/wine',
    method: 'get',
  },
  {
    action: '_getMembershipFilterList',
    property: 'membershipFilterList',
    path:'filter/membership',
    method:'get'
  }
]);

export default {
  namespaced: true,
  mixins: [apiStore],
  state: {
    // wineFilterList: [],
    membershipFilterList: []
  },
  actions: {
    async getWineFilterList({ dispatch }, params) {
      const response = await dispatch(`_getWineFilterList`, { params });

      const list = response.data.map((item) => {
        const changeValue = {};

        changeValue.name = item.filter_category_name;
        changeValue.isOpen = item.isOpen;
        changeValue.list = item.subCategories.map((subItem) => ({
          key: subItem.filter_sub_category_no,
          name: subItem.filter_sub_category_name,
          select: subItem.select,
        }));



        return changeValue;
      });


      return list;


      // return Array.from(data);
    },
    async getMembershipFilterList({ state, dispatch }, data) {
      const response = await dispatch(`_getMembershipFilterList`, { data });

      state.wineFilterList = response.data.map((item) => {
        const changeValue = {};

        changeValue.name = item.filter_category_name;
        changeValue.isOpen = item.isOpen;
        changeValue.list = item.subCategories.map((subItem) => ({
          key: subItem.filter_sub_category_no,
          name: subItem.filter_sub_category_name,
          select: subItem.select,
        }));

        return changeValue;
      });

      return state.wineFilterList;
    },
  },
  mutations: {},
};
