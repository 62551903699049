import Vue from 'vue'
import App from './App.vue'

import router from './router'
import store from './store'
import { sync } from 'vuex-router-sync'
import CKEditor from '@ckeditor/ckeditor5-vue2';
import VueMobileDetection from 'vue-mobile-detection'
import InfiniteLoading from 'vue-infinite-loading'
import lib from './utils/lib.js'
import gsap from 'gsap'
import browserDetect from "vue-browser-detect-plugin";
import vClickOutside from 'v-click-outside'


//using to marterial icons(font) from google (https://fonts.google.com/icons)
//setting ref. https://www.npmjs.com/package/@dbetka/vue-material-icons
import { AIcon, IconComputed } from '@dbetka/vue-material-icons';
Vue.prototype.$auth = {
  isLogin: false,
  isMinter: true,
  memberInfo: null
}
Vue.use(IconComputed);
Vue.use(VueMobileDetection)
Vue.use(InfiniteLoading)
Vue.use(lib)
Vue.use(browserDetect)
Vue.use(vClickOutside)

Vue.prototype.$gsap = gsap;
Vue.component('g-icon', AIcon);


sync(store, router);
Vue.use( CKEditor );

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  components: {
  },
  render: h => h(App),
}).$mount('#app');
