import { createApiStore } from '../../api';

const apiStore = createApiStore([
  {
    action: '_getCreator',
    property: 'creator',
    path: 'nft_creator/get',
    method: 'get',
  }
]);

export default {
  namespaced: true,
  mixins: [apiStore],
  state: {
    creator: {}
  },
  actions: {
    async getCreator({ state, dispatch }, params) {
      const response = await dispatch(`_getCreator`, { params });
      state.creator = response.data
      return state.creator;
    },

  },
  mutations: {},
};
