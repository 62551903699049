import {asyncView} from './utils'

function getMypageHeader (title) {
    return {
        render (createElement) {
            return createElement('div', {
                class:'title',
            }, title)
        }
    }
}

const configRouter = configRoutes()

function configRoutes() {
    return [
        {
            path: '/',
            components: {
                default: asyncView('Index')
            },
            children: [
                {
                    path: '',
                    name: 'Home',
                    components: {
                        default: asyncView('home/Index'),
                    },
                    meta: {
                        action: true
                    }
                }

            ]
        },
        {
            path: '/about',
            components: {
                default: asyncView('Index')
            },
            children: [
                {
                    path: 'intro',
                    name: 'About-Intro',
                    components: {
                        default: asyncView('about/Intro'),
                    }
                },
                {
                    path: 'terms',
                    name: 'Terms-And-Privacy',
                    components: {
                        default: asyncView('about/Terms'),
                    }
                }

            ]
        },
        {
            path: '/users',
            components: {
                default: asyncView('Index')
            },
            children: [
                {
                    path: 'login',
                    name: 'Login',
                    components: {
                        default: asyncView('users/Login'),
                    },
                    meta: {
                        // action: true
                    }
                }

            ]
        },
        {
            path: '/market',
            components: {
                default: asyncView('Index')
            },
            meta: {
                // needAuth: true
            },
            children: [
                {
                    path: 'list',
                    name: 'MarketNftList',
                    components: {
                        default: asyncView('market/NftList'),
                        //header: getDefaultHeader('타이틀 텍스트 파라미터 - 고정 제목에 활용')
                    },
                    meta: {
                        // action: true
                    }
                },
                {
                    path: 'list/sale',
                    name: 'NftListSale',
                    components: {
                        default: asyncView('market/NftListSale'),
                    },
                },
                {
                    path: `detail/:address/:tokenId`,
                    name: 'NftDetail',
                    components: {
                        default: asyncView(`market/NftDetail`),
                    }
                },
                {
                    path: `membership`,
                    name: 'membershipMinting',
                    components: {
                        default: asyncView(`market/MembershipMinting`),
                    }
                },
                {
                    path: `membership/list`,
                    name: 'MarketMembershipNftList',
                    components: {
                        default: asyncView(`market/MembershipNftList`),
                    }
                },
                {
                    path: `membership/list/sale`,
                    name: 'membershipNftListSale',
                    components: {
                        default: asyncView(`market/MembershipNftListSale`),
                    }
                },
                {
                    path: `membership/detail/:address/:tokenId`,
                    name: 'MembershipDetail',
                    components: {
                        default: asyncView('market/MembershipNftDetail')
                    }
                }

            ]
        },
        {
            path: '/membership',
            components: {
                default: asyncView('Index')
            },
            meta: {
                // needAuth: true
            },
            children: [
                {
                    path: '',
                    name: 'Index',
                    components: {
                        default: asyncView('membership/Index')
                    },
                    meta: {
                        // action: true
                    }
                }
            ]
        },
        {
            path: '/users',
            components: {
                default: asyncView('Index')
            },
            meta: {
                // needAuth: true
            },
            children: [
                {
                    path: 'mypage',
                    components: {
                        default: asyncView('users/mypage/Index')
                    },
                    children: [
                        {
                            path: '',
                            name: 'NftList',
                            components: {
                                title: getMypageHeader('Wine NFT'),
                                default: asyncView('users/mypage/NftList')
                            },
                            meta: {
                                // action: true
                                needAuth: true
                            }
                        },
                        {
                            path: 'membership',
                            name: 'MembershipNftList',
                            components: {
                                title: getMypageHeader('Membership'),
                                default: asyncView('users/mypage/MembershipList')
                            },
                            meta: {
                                needAuth: true
                            }
                        },

                        {
                            path: 'activity',
                            name: 'Activity',
                            components: {
                                title: getMypageHeader('Activity'),
                                default: asyncView('users/mypage/Activity')
                            },
                            meta: {
                                loading: true
                            }
                        },
                        {
                            path: 'profile',
                            name: 'Profile',
                            components: {
                                title: getMypageHeader('Profile'),
                                default: asyncView('users/mypage/Profile')
                            }
                        },
                        {
                            path: 'profile/edit',
                            name: 'EditProfile',
                            components: {
                                title: getMypageHeader('Profile'),
                                default: asyncView('users/mypage/EditProfile')
                            }
                        },
                    ]
                },
                /*{
                    path: 'mypage',
                    name: '',
                    components: {
                        default: asyncView('users/mypage/Index')
                    },
                    meta: {
                        // action: true
                    }
                },*/
                {
                    path: 'minter/create/step1',
                    name: '',
                    components: {
                        default: asyncView('users/minter/CreateNftStep1')
                    },
                    meta: {
                        // action: true
                    }
                },
                /*{
                    path: 'minter/create/step2',
                    name: '',
                    components:  {
                        default: asyncView('users/minter/CreateNftStep2')
                    },
                    meta: {
                        // action: true
                    }
                },
                {
                    path: 'minter/create/step3',
                    name: '',
                    components:  {
                        default: asyncView('users/minter/CreateNftStep3')
                    },
                    meta: {
                        // action: true
                    }
                }*/
            ]
        },
        {
            path: '/cs',
            components: {
                default: asyncView('Index')
            },
            meta: {
                // needAuth: true
            },
            children: [
                {
                    path: 'guide',
                    name: 'Guide',
                    components: {
                        default: asyncView('cs/Guide')
                    },
                    meta: {
                        // action: true
                    }
                },
                {
                    path: 'guide/detail/:no',
                    name: 'PC 이용 가이드 상세',
                    components: {
                        default: asyncView('cs/GuideDetail')
                    },
                    meta: {
                        // action: true
                    }
                },
                {
                    path: 'guide/mobile/detail/:no',
                    name: 'mobile 이용 가이드 상세',
                    components: {
                        default: asyncView('cs/MobileGuideDetail')
                    },
                    meta: {
                        // action: true
                    }
                },
                {
                    path: 'faq',
                    name: 'FAQ',
                    components: {
                        default: asyncView('cs/Faq')
                    },
                    meta: {
                        // action: true
                    }
                },
                {
                    path: 'notice',
                    name: 'Notice',
                    components: {
                        default: asyncView('cs/Notice')
                    },
                    meta: {
                        // action: true
                    }
                },
                {
                    path: 'notice/:no',
                    name: 'NoticeDetail',
                    components: {
                        default: asyncView('cs/NoticeDetail')
                    },
                    meta: {
                        // action: true
                    }
                },
                {
                    path: 'press',
                    name: 'Press',
                    components: {
                        default: asyncView('cs/Press')
                    },
                    meta: {
                        // action: true
                    }
                },
                {
                    path: 'press/:no',
                    name: 'PressDetail',
                    components: {
                        default: asyncView('cs/PressDetail')
                    },
                    meta: {
                        // action: true
                    }
                },
            ]
        },
        {
            path: '/redeem',
            name: 'redeem',
            components: {
                default: asyncView('Index')
            },
            children: [
                {
                    path: '',
                    name: 'redeemIndex',
                    components: {
                        default: asyncView('redeem/Redeem'),
                    }
                },
                {
                    path: 'complete/:no',
                    name: 'redeemComplete',
                    components: {
                        default: asyncView('redeem/Complete'),
                    },
                    meta:{
                        needAuth: true
                    }
                },
                {
                    path: 'edit/:redeem_no',
                    name: 'redeemEdit',
                    components: {
                        default: asyncView('redeem/Edit'),
                    },
                    meta:{
                        action: true,
                        needAuth: true
                    }
                }

            ]
        },
        {
            path: '/components',
            name: 'components',
            components: {
                default: asyncView('Components')
            }
        },
        {
            path: '/pcList',
            name: 'pcList',
            components: {
                default: asyncView('PcWineListSample')
            }
        },
        {
            path: '/mobileList',
            name: 'mobileList',
            components: {
                default: asyncView('MobileWineListSample')
            }
        },
        {
            path: '/wine/nft/data',
            name: 'WineNFTDatas',
            components: {
                default: asyncView('WineNFTDatas')
            }
        },
        {
            path: '/membership/nft/data',
            name: 'MembershipNFTDatas',
            components: {
                default: asyncView('MembershipNFTDatas')
            }
        }
    ]
}

export default configRouter
