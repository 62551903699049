import { createApiStore } from '../../api';

const apiStore = createApiStore([
  {
    action: '_getRedeemList',
    property: 'redeemList',
    path: 'redeem/list',
    method: 'get',
  },
  {
    action: '_getRedeem',
    property: 'redeemInfo',
    path: 'redeem/get',
    method: 'get',
  },
  {
    action: '_postRedeem',
    path: 'redeem/append',
    method: 'post',
  },
  {
    action: '_cancelRedeem',
    path: 'redeem/cancel/{nft_key}',
    method: 'post',
    pathParams: ['nft_key']
  },
  {
    action: '_editRedeem',
    path: 'redeem/{redeem_no}/{wine_nft_no}',
    method: 'put',
    pathParams: ['redeem_no', 'wine_nft_no']
  }
]);

export default {
  namespaced: true,
  mixins: [apiStore],
  state: {
    redeemList: [],
    redeemInfo:{}
  },
  actions: {
    async getRedeemList({ state, dispatch }, params) {
      const response = await dispatch(`_getRedeemList`, { params });
      state.redeemList = response.data
      return state.redeemList;
    },
    async getReedem({ state, dispatch }, params) {
      const response = await dispatch(`_getRedeem`, { params });
      state.redeemInfo = response.data
      return state.redeemInfo;
    },
    async appendRedeem({ dispatch }, data) {
      return await dispatch(`_postRedeem`, {data});

    },
    async cancelRedeem({dispatch}, data){
      return await dispatch('_cancelRedeem', {
        params: {nft_key: data.nft_key},
        data: data
      });
    },
    async editRedeem({dispatch}, data){
      return await dispatch('_editRedeem', {
        params: {
          redeem_no: data.redeem_no,
          wine_nft_no: data.wine_nft_no
        },
        data: data
      })
    }



  },
  mutations: {},
};
