import getContractApi, { ServiceType } from "@/api/contract";
//
const contract = getContractApi();
//
// config for production
export default {
    // apiBaseUrl: `http://localhost:8000/api/`,
    apiBaseUrl: process.env.VUE_APP_API ?? (() => {
        switch (contract.getServiceType()) {
            case ServiceType.Main:
                return "https://api.winex.ai/api";
            case ServiceType.Alpha:
                return "http://alpha-api.winex.ai/api";
            case ServiceType.Testnet:
            default:
                return "http://test-api.winex.ai/api";
        }
    })(),
    // apiBaseUrl: `http://test-api.winex.ai/api` // 테스트용.. .env로 전역변수로 뺄 지는 회의 이후에 처리 해야 할 것 같습니다.
};

