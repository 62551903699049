import Vue from 'vue'
import Router from 'vue-router'
import { scrollBehavior } from './utils'
import routes from './routes'
// import { auth, dispatchAction, checkAccessToken } from './hooks'
import {auth, isLoading} from './hooks'

Vue.use(Router);


const router = new Router({
  mode: 'history',
  scrollBehavior,
  routes
});

// router.beforeEach(checkAccessToken)
router.beforeEach(auth)
router.beforeEach(isLoading)
// router.beforeEach(dispatchAction)
// router.beforeEach(metaTagCheck);


export default router