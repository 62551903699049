import { createApiStore } from '../../api';

const apiStore = createApiStore([
  {
    action: '_appendMembershipMail',
    property: 'appendMailInfo',
    path: 'membership/save/mail',
    method: 'post'
  },
]);

export default {
  namespaced: true,
  mixins: [apiStore],
  state: {
    appendMailInfo: {},
  },
  actions: {
    async appendMembershipMail({ state, dispatch }, data) {
      await dispatch(`_appendMembershipMail`, { data });
      return state.appendMailInfo;
    },
  },
  mutations: {},
};
