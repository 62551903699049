import { createApiStore } from '../../api';

const apiStore = createApiStore([
    {
        action: '_getNftCreator',
        property: 'nftCreator',
        path: 'nft_creator/list',
        method: 'get'
    }
]);

export default {
    namespaced: true,
    mixins: [apiStore],
    state: {
        nftCreator: null
    },
    actions: {
        async getNftCreator({ state, dispatch }, params) {
            // console.dir(1)
            const response = await dispatch(`_getNftCreator`, { params });
            // console.dir(2)
            state.nftCreator = response.data[0]
            return state.nftCreator;
        },

    },
    mutations: {},
};
