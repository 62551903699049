import { createApiStore } from '../../api';

const apiStore = createApiStore([
  {
    action: '_getHomeWineInfo',
    property: 'homeWineInfo',
    path: 'nft/count_info',
    method: 'get'
  }
]);

export default {
  namespaced: true,
  mixins: [apiStore],
  state: {
    homeWineInfo:[]
  },
  actions: {
    async getBestWineList({ state, dispatch }, params) {
      const response = await dispatch(`_getHomeWineInfo`, { params });
      state.homeWineInfo = response.data
      return state.homeWineInfo;
    },

  },
  mutations: {},
};
