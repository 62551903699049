import { createApiStore } from '../../api';

const apiStore = createApiStore([
  {
    action: '_getTermList',
    property: 'termList',
    path: 'terms/list',
    method: 'get',
  }
]);

export default {
  namespaced: true,
  mixins: [apiStore],
  state: {
    termList: []
  },
  actions: {
    async getTermList({ state, dispatch }, params) {
      // console.dir(params)
      const response = await dispatch(`_getTermList`, { params });
      state.termList = response.data
      return state.termList;
    },

  },
  mutations: {},
};
