<template>
    <div class="back_capture" v-if="loading">
        <div class="winex-loading-wrap">
            <div class="spinner" >

            </div>
            <div class="loading-title">{{title}}</div>
            <div class="loading-content">{{content}}</div>
        </div>
    </div>

</template>


<script>
    import { mapState } from 'vuex'

    export default {
        computed: {
            ...mapState('loading', ['loading','title','content'])
        }
    }
</script>


<style lang="scss">
    @import "@/assets/scss/variables";
    .back_capture {
        overflow: auto;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0,0,0,0.25);
        text-align: center;
        z-index: 100000000;
        padding: 100px;
    }
    .winex-loading-wrap {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .spinner {
        width: 225px;
        height: 225px;
        @media (max-width: 768px) {
            width: 135px;
            height: 135px;
            background-image: url(~@/assets/images/loading/ellipse-mobile.png);
        }
        text-align: center;
        border-radius: 50%;

        -webkit-animation: loading-animation 1.5s infinite ease-in-out;
        animation: loading-animation 1.5s infinite ease-in-out;
        background-image: url(~@/assets/images/loading/ellipse.png);
        background-size: contain;
        background-repeat: no-repeat;
        margin: 0 auto;
    }

    .loading-title {
        font-weight: 700;
        font-size: 42px;
        line-height: 64px;
        text-align: center;
        letter-spacing: -0.02em;
        margin-top: 76px;
        color: #FFF;
        word-break: keep-all;
        @media (max-width: 768px) {
            margin-top: 40px;
            font-weight: 700;
            font-size: 18px;
            line-height: 32px;
            letter-spacing: -0.04em;
        }
    }

    .loading-content {
        font-weight: 500;
        font-size: 24px;
        line-height: 44px;
        text-align: center;
        letter-spacing: -0.02em;
        margin-top: 20px;
        max-width: 520px;
        color: #FFF;
        word-break: keep-all;
        @media (max-width: 768px) {
            font-weight: 500;
            font-size: 16px;
            line-height: 28px;
            letter-spacing: -0.04em;
            margin-top: 10px;
            max-width: 220px;
        }

    }


    @-webkit-keyframes loading-animation {
        0% {transform: rotate(0deg)}

        100% {transform: rotate(360deg)}
    }
</style>
