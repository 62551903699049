import { createApiStore } from '../../api';

const apiStore = createApiStore([
  {
    action: '_getPopupList',
    property: 'popupList',
    path: 'popup/list',
    method: 'get',
  }
]);

export default {
  namespaced: true,
  mixins: [apiStore],
  state: {
    popupList: []
  },
  actions: {
    async getPopupList({ state, dispatch }, params) {
      const response = await dispatch(`_getPopupList`, { params });
      state.popupList = response.data
      return state.popupList;
    },

  },
  mutations: {},
};
