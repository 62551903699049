<template>
  <div id="app">
    <router-view id="contents" :key="$route.fullPath"></router-view>
    <Loading></Loading>
  </div>
</template>

<script>

  import Loading from "./components/common/Loading";
  export default {
    name: 'App',
    components: {
      Loading
    },
    mounted() {
      // this.$store.commit('loading/done')
    }
  }
</script>
<style lang="scss">
  @import "~@/assets/scss/style";
</style>
