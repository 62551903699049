import { createApiStore } from '../../api';

const apiStore = createApiStore([
  {
    action: '_getBestWineList',
    property: 'bestWineList',
    path: 'best_wine/list',
    method: 'get'
  }
]);

export default {
  namespaced: true,
  mixins: [apiStore],
  state: {
    bestWineList:[]
  },
  actions: {
    async getBestWineList({ state, dispatch }, params) {
      const response = await dispatch(`_getBestWineList`, { params });
      state.bestWineList = response.data
      return state.bestWineList;
    },

  },
  mutations: {},
};
