import { createApiStore } from '../../api';

const apiStore = createApiStore([
  {
    action: '_getFaqList',
    property: 'faqList',
    path: 'faq/list',
    method: 'get',
  }
]);

export default {
  namespaced: true,
  mixins: [apiStore],
  state: {
    faqList: []
  },
  actions: {
    async getFaqList({ state, dispatch }, params) {
      const response = await dispatch(`_getFaqList`, { params });
      state.noticeList = response.data
      return state.noticeList;
    },

  },
  mutations: {},
};
