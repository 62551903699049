import { createApiStore } from '../../api';

const apiStore = createApiStore([
    {
        action: '_getUserProfile',
        property: 'userProfile',
        path: 'user/profile',
        method: 'get',
    },
    {
        action: '_modifyUserProfile',
        property: 'userProfile',
        path: 'user/profile',
        method: 'put',
    },
]);

export default {
    namespaced: true,
    mixins: [apiStore],
    state: {

    },
    actions: {
        async getUserProfile({ state, dispatch }, params) {
            await dispatch(`_getUserProfile`, { params });
            return state.userProfile;
        },
        async modifyUserProfile({ state, dispatch }, data) {
            await dispatch(`_modifyUserProfile`, { data });
            return state.userProfile;
        },

    },
    mutations: {},
};
