import { createApiStore } from '../../api';

const apiStore = createApiStore([
  {
    action: '_getNoticeList',
    property: 'noticeList',
    path: 'notice/list',
    method: 'get',
  },
  {
    action: '_getNoticeView',
    property: 'noticeInfo',
    path: ({no}) => `notice/${no}`,
    method: 'get',
  }
]);

export default {
  namespaced: true,
  mixins: [apiStore],
  state: {
    noticeList: [],
    noticeInfo:{}
  },
  actions: {
    async getNoticeList({ state, dispatch }, params) {
      const response = await dispatch(`_getNoticeList`, { params });
      state.noticeList = response.data
      return state.noticeList;
    },
    async getNoticeView({ state, dispatch }, params) {
      const response = await dispatch(`_getNoticeView`, params);
      state.noticeInfo = response.data
      return state.noticeInfo;
    },

  },
  mutations: {},
};
