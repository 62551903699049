import { createApiStore } from '../../api';

const apiStore = createApiStore([
  {
    action: '_appendWineNFT',
    path: 'nft/create/wine',
    method: 'post',
    headers: {
      "Content-Type": "multipart/form-data"
    }
  },
  {
    action:'_setWineNFT',
    path:'nft/set/wine',
    method:'post',
    async: true
  },
  {
    action:'_setMembershipNFT',
    path:'nft/create/membership',
    method:'post',
    async: true
  },
  {
    action: '_getWineNFT',
    path: 'nft/wine',
    method: 'get',
    property: 'wineNFT'
  }
]);

export default {
  namespaced: true,
  mixins: [apiStore],
  state: {

  },
  actions: {
    async appendWineNFT({  dispatch }, data) {
      return await dispatch(`_appendWineNFT`, {data})
    },
    async setWineNFT({dispatch}, data) {
      return await dispatch(`_setWineNFT`, {data})
    },
    async setMembershipNFT({dispatch}, data) {
      return await dispatch(`_setMembershipNFT`, {data})
    },
    async getWineNFT({dispatch}, params){
      return await dispatch('_getWineNFT', {params});
    }
  },
  mutations: {},
};
